define('ember-paper-swiper/components/paper-swiper/media', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['paper-swiper-slide-media'],
    attributeBindings: ['backgroundStyle:style'],

    backgroundStyle: Ember.computed('src', function () {
      let src = this.get('src');
      return Ember.String.htmlSafe(`background-image: url("${src}")`);
    })
  });
});