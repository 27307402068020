define("ember-changeset-validations/index", ["exports", "ember-changeset-validations/utils/wrap", "ember-changeset-validations/utils/handle-multiple-validations", "validated-changeset"], function (_exports, _wrap, _handleMultipleValidations, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = lookupValidator;

  function lookupValidator(validationMap = {}) {
    return ({
      key,
      newValue,
      oldValue,
      changes,
      content
    }) => {
      let validator = validationMap[key];

      if (Ember.isEmpty(validator)) {
        return true;
      }

      if (Ember.isArray(validator)) {
        return (0, _handleMultipleValidations.default)(validator, {
          key,
          newValue,
          oldValue,
          changes,
          content
        });
      }

      let validation = validator(key, newValue, oldValue, changes, content);
      return (0, _validatedChangeset.isPromise)(validation) ? validation.then(_wrap.default) : [validation];
    };
  }
});