define("ember-paper-swiper/templates/components/paper-swiper-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5VXbp4IM",
    "block": "{\"symbols\":[\"b\",\"container\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"paper-swiper-content-container \",[21,\"class\"]]]],[9],[0,\"\\n\"],[4,\"paper-swiper/container\",null,[[\"currentSlide\",\"childComponents\",\"swiper\",\"calloutId\"],[[23,[\"currentSlide\"]],[23,[\"childComponents\"]],[22,0,[]],[23,[\"calloutId\"]]]],{\"statements\":[[0,\"    \"],[14,3,[[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"paper-swiper-fixed-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"id\",[21,\"calloutId\"]],[11,\"class\",\"paper-swiper-callout\"],[9],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"paper-swiper-pagination\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"bullets\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"paper-swiper-pagination-bullet \",[27,\"if\",[[22,1,[\"isActive\"]],\"active\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"currentSlide\"]]],null],[22,1,[\"index\"]]],null]],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-swiper/templates/components/paper-swiper-inline.hbs"
    }
  });

  _exports.default = _default;
});