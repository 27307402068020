define('ember-paper-swiper/components/paper-swiper/callout', ['exports', 'ember-paper-swiper/templates/components/paper-swiper/callout'], function (exports, _callout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _callout.default,
    tagName: ''
  });
});