define("ember-paper/templates/components/paper-virtual-repeat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JO8t7BPW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"paper-virtual-repeat-scroller\",null,[[\"onScroll\"],[[27,\"action\",[[22,0,[]],\"onScroll\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"md-virtual-repeat-sizer\"],[12,\"style\",[21,\"contentStyle\"]],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"ignoreOffsetter\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-virtual-repeat-offsetter\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-virtual-repeat-offsetter\"],[12,\"style\",[21,\"offsetterStyle\"]],[9],[0,\"\\n      \"],[14,1,[[23,[\"rawVisibleItems\"]],[23,[\"visibleItems\"]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"ignoreOffsetter\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,[\"rawVisibleItems\"]],[23,[\"visibleItems\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-virtual-repeat.hbs"
    }
  });

  _exports.default = _default;
});