define("ember-paper/templates/components/paper-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Sqv3Ev/I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"md-tabs-wrapper\"],[12,\"class\",[27,\"if\",[[23,[\"shouldStretch\"]],\"md-stretch-tabs\"],null]],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"shouldPaginate\"]]],null,{\"statements\":[[0,\"    \"],[7,\"md-prev-button\"],[11,\"role\",\"button\"],[12,\"class\",[27,\"unless\",[[23,[\"canPageBack\"]],\"md-disabled\"],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"previousPage\"],null]],[9],[0,\"\\n      \"],[1,[27,\"paper-icon\",[\"keyboard-arrow-left\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"md-next-button\"],[11,\"role\",\"button\"],[12,\"class\",[27,\"unless\",[[23,[\"canPageForward\"]],\"md-disabled\"],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"nextPage\"],null]],[9],[0,\"\\n      \"],[1,[27,\"paper-icon\",[\"keyboard-arrow-left\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"md-tabs-canvas\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"shouldPaginate\"]],\"md-paginated\"],null],\" \",[27,\"if\",[[23,[\"shouldCenter\"]],\"md-center-tabs\"],null]]]],[11,\"tabindex\",\"-1\"],[11,\"role\",\"tablist\"],[9],[0,\"\\n    \"],[7,\"md-pagination-wrapper\"],[12,\"class\",[27,\"if\",[[23,[\"shouldCenter\"]],\"md-center-tabs\"],null]],[12,\"style\",[27,\"if\",[[23,[\"shouldPaginate\"]],[23,[\"paginationStyle\"]]],null]],[9],[0,\"\\n\\n      \"],[14,1,[[27,\"hash\",null,[[\"tab\"],[[27,\"component\",[\"paper-tab\"],[[\"noInk\",\"selected\",\"onSelect\"],[[23,[\"noInk\"]],[23,[\"selected\"]],[27,\"action\",[[22,0,[]],\"onChange\"],null]]]]]]]]],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"inkBar\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"paper-ink-bar\",null,[[\"movingRight\",\"left\",\"right\"],[[23,[\"movingRight\"]],[23,[\"inkBar\",\"left\"]],[23,[\"inkBar\",\"right\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-tabs.hbs"
    }
  });

  _exports.default = _default;
});