define("ember-paper/templates/components/paper-progress-circular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PcbEhcA8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12,\"viewBox\",[28,[\"0 0 \",[21,\"diameter\"],\" \",[21,\"diameter\"]]]],[12,\"style\",[21,\"svgStyle\"]],[9],[0,\"\\n  \"],[7,\"path\"],[11,\"fill\",\"none\"],[12,\"style\",[21,\"pathStyle\"]],[12,\"stroke-dasharray\",[21,\"strokeDasharray\"]],[12,\"d\",[21,\"d\"]],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-progress-circular.hbs"
    }
  });

  _exports.default = _default;
});