define("ember-paper-swiper/templates/components/paper-swiper/slide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LNI69eb4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"media\",\"headline\",\"subhead\"],[[27,\"component\",[\"paper-swiper/media\"],null],[27,\"component\",[\"paper-swiper/headline\"],[[\"destinationId\"],[[23,[\"headlineId\"]]]]],[27,\"component\",[\"paper-swiper/subhead\"],[[\"destinationId\"],[[23,[\"subheadId\"]]]]]]]]]],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"paper-swiper-text\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[21,\"headlineId\"]],[11,\"class\",\"paper-swiper-headline\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[21,\"subheadId\"]],[11,\"class\",\"paper-swiper-subhead\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"paper-swiper-fixed-content-placeholder\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-swiper/templates/components/paper-swiper/slide.hbs"
    }
  });

  _exports.default = _default;
});