define("ember-paper/templates/components/paper-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zpBFy7cs",
    "block": "{\"symbols\":[\"controls\",\"&default\"],\"statements\":[[4,\"with\",[[27,\"hash\",null,[[\"checkbox\",\"button\",\"switch\",\"radio\"],[[27,\"component\",[\"paper-checkbox\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[22,0,[]],false,true]]],[27,\"component\",[\"paper-button\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\",\"skipProxy\"],[[22,0,[]],false,true,true]]],[27,\"component\",[\"paper-switch\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[22,0,[]],false,true]]],[27,\"component\",[\"paper-radio-proxiable\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[22,0,[]],false,true]]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"hasPrimaryAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-button md-no-style\"],[9],[0,\"\\n      \"],[1,[27,\"paper-button\",null,[[\"class\",\"onClick\",\"href\",\"target\",\"onMouseEnter\",\"onMouseLeave\"],[\"md-no-style\",[23,[\"onClick\"]],[23,[\"href\"]],[23,[\"target\"]],[27,\"action\",[[22,0,[]],[23,[\"mouseEnter\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"mouseLeave\"]]],null]]]],false],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-list-item-inner\"],[9],[0,\"\\n        \"],[14,2,[[22,1,[]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"md-no-style md-list-item-inner\"],[9],[0,\"\\n      \"],[14,2,[[22,1,[]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-item.hbs"
    }
  });

  _exports.default = _default;
});