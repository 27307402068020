define("ember-paper/templates/components/paper-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAw/TzFK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"md-slider-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md-slider-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"md-track-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-track\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-track md-track-fill\"],[12,\"style\",[21,\"activeTrackStyle\"]],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-track-ticks\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"md-thumb-container\"],[12,\"style\",[21,\"thumbContainerStyle\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-thumb\"],[11,\"style\",\"touch-action: none\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-focus-thumb\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-focus-ring\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-sign\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"md-thumb-text\"],[9],[1,[21,\"value\"],false],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"md-disabled-thumb\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-slider.hbs"
    }
  });

  _exports.default = _default;
});