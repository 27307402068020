define('ember-pdf-js/services/pdf-js', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { getOwner, Service } = Ember;

  exports.default = Service.extend({
    init() {
      this._super(...arguments);

      let appConfig = getOwner(this).resolveRegistration('config:environment');
      let addonConfig = appConfig.emberPdfJs;

      this.PDFJS = PDFJS;
      this.PDFJS.workerSrc = addonConfig.workerSrc;
    }
  });
});