define("ember-paper/templates/components/paper-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rNLilwR7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[23,[\"destinationEl\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[1,[27,\"paper-backdrop\",null,[[\"locked-open\",\"opaque\",\"fixed\",\"class\",\"onClick\"],[[23,[\"isLockedOpen\"]],[23,[\"opaque\"]],[27,\"unless\",[[23,[\"parent\"]],true],null],\"md-dialog-backdrop\",[27,\"action\",[[22,0,[]],\"outsideClicked\"],null]]]],false],[0,\"\\n\"],[4,\"paper-dialog-container\",null,[[\"class\",\"outsideClicked\"],[[27,\"readonly\",[[23,[\"dialogContainerClass\"]]],null],[27,\"action\",[[22,0,[]],\"outsideClicked\"],null]]],{\"statements\":[[4,\"paper-dialog-inner\",null,[[\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\"],[[27,\"readonly\",[[23,[\"class\"]]],null],[23,[\"origin\"]],[23,[\"defaultedParent\"]],[23,[\"defaultedOpenFrom\"]],[23,[\"defaultedCloseTo\"]],[23,[\"fullscreen\"]],[23,[\"focusOnOpen\"]]]],{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-dialog.hbs"
    }
  });

  _exports.default = _default;
});