define("ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RHopgTRF",
    "block": "{\"symbols\":[\"controls\",\"&default\"],\"statements\":[[4,\"paper-item\",null,[[\"class\",\"onClick\",\"noink\"],[[23,[\"class\"]],[23,[\"onClose\"]],true]],{\"statements\":[[0,\"  \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/header.hbs"
    }
  });

  _exports.default = _default;
});