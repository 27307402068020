define('emberx-file-input/test-helpers/select-file-unit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (selector, file) {
    let changeEvent = buildChangeEvent({ testingFiles: [file] });
    $(selector)[0].dispatchEvent(changeEvent);
  };

  function buildChangeEvent(options = {}) {
    let event = document.createEvent('Events');
    event.initEvent('change', true, true);
    $.extend(event, options);
    return event;
  }
});