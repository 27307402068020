define("ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w44JKzBM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[21,\"paper-divider\"],false],[0,\"\\n\"],[7,\"md-expansion-panel-footer\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/footer.hbs"
    }
  });

  _exports.default = _default;
});