define("ember-paper/templates/components/paper-radio-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BaVVMIG+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"md-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md-off\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md-on\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"md-label\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"md-label\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      \"],[1,[21,\"label\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-radio-base.hbs"
    }
  });

  _exports.default = _default;
});