define("ember-paper/templates/components/paper-grid-tile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TzfhPTQo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"figure\"],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"footer\"],[[27,\"component\",[\"paper-grid-tile-footer\"],null]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-grid-tile.hbs"
    }
  });

  _exports.default = _default;
});