define("ember-paper-expansion-panel/templates/components/paper-expansion-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FD2OGNaL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"collapsed\",\"expanded\",\"collapse\",\"expand\",\"isExpanded\"],[[27,\"component\",[\"paper-expansion-panel/collapsed\"],[[\"expanded\",\"onExpand\"],[[23,[\"expanded\"]],[27,\"action\",[[22,0,[]],[23,[\"expand\"]]],null]]]],[27,\"component\",[\"paper-expansion-panel/expanded\"],[[\"expanded\",\"onClose\"],[[23,[\"expanded\"]],[27,\"action\",[[22,0,[]],[23,[\"collapse\"]]],null]]]],[27,\"action\",[[22,0,[]],[23,[\"collapse\"]]],null],[27,\"action\",[[22,0,[]],[23,[\"expand\"]]],null],[23,[\"expanded\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-expansion-panel/templates/components/paper-expansion-panel.hbs"
    }
  });

  _exports.default = _default;
});