define("ember-paper/templates/components/paper-autocomplete-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K2vwjeQY",
    "block": "{\"symbols\":[\"opt\",\"&default\"],\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"id\",[27,\"if\",[[27,\"eq\",[[22,1,[\"raw\"]],[23,[\"select\",\"highlighted\"]]],null],\"selected_option\"],null]],[11,\"class\",\"ember-power-select-option\"],[12,\"aria-selected\",[27,\"ember-power-select-is-selected\",[[22,1,[\"raw\"]],[23,[\"select\",\"selected\"]]],null]],[12,\"aria-disabled\",[27,\"ember-power-select-true-string-if-present\",[[22,1,[\"disabled\"]]],null]],[12,\"aria-current\",[27,\"eq\",[[22,1,[\"raw\"]],[23,[\"select\",\"highlighted\"]]],null]],[12,\"data-option-index\",[28,[[21,\"groupIndex\"],[22,1,[\"virtualIndex\"]]]]],[12,\"data-option-actual-index\",[28,[[21,\"groupIndex\"],[22,1,[\"actualIndex\"]]]]],[11,\"role\",\"option\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n    \"],[14,2,[[22,1,[\"raw\"]],[23,[\"select\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-autocomplete-options.hbs"
    }
  });

  _exports.default = _default;
});