define("ember-power-select/templates/components/power-select/trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SuhA/OsP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"select\",\"selected\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"selectedItemComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"component\",[[23,[\"selectedItemComponent\"]]],[[\"option\",\"select\"],[[27,\"readonly\",[[23,[\"select\",\"selected\"]]],null],[27,\"readonly\",[[23,[\"select\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"ember-power-select-selected-item\"],[9],[14,1,[[23,[\"select\",\"selected\"]],[23,[\"select\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[27,\"and\",[[23,[\"allowClear\"]],[27,\"not\",[[23,[\"select\",\"disabled\"]]],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"ember-power-select-clear-btn\"],[12,\"onmousedown\",[27,\"action\",[[22,0,[]],\"clear\"],null]],[12,\"ontouchstart\",[27,\"action\",[[22,0,[]],\"clear\"],null]],[9],[0,\"×\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"placeholderComponent\"]]],[[\"placeholder\"],[[23,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"span\"],[11,\"class\",\"ember-power-select-status-icon\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-power-select/templates/components/power-select/trigger.hbs"
    }
  });

  _exports.default = _default;
});