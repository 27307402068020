define("ember-paper-swiper/templates/components/paper-swiper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+6NXqLXE",
    "block": "{\"symbols\":[\"container\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[23,[\"destinationId\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"paper-backdrop\",null,[[\"opaque\",\"class\"],[true,\"md-dialog-backdrop\"]]],false],[0,\"\\n\\n\"],[4,\"paper-dialog-container\",null,[[\"class\"],[\"paper-swiper-dialog-container\"]],{\"statements\":[[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"raised\",\"disabled\",\"onClick\"],[\"hide-sm hide-xs paper-swiper-previous-button\",true,true,[23,[\"isFirst\"]],[27,\"action\",[[22,0,[]],\"previousSlide\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[\"arrow_back\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-dialog-inner\",null,[[\"class\",\"fullscreen\"],[\"paper-swiper-dialog\",true]],{\"statements\":[[0,\"\\n\"],[4,\"paper-swiper-inline\",null,[[\"class\",\"currentSlide\"],[\"flex\",[23,[\"currentSlide\"]]]],{\"statements\":[[0,\"        \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-button\",null,[[\"class\",\"iconButton\",\"raised\",\"disabled\",\"onClick\"],[\"hide-sm hide-xs paper-swiper-next-button\",true,true,[23,[\"isLast\"]],[27,\"action\",[[22,0,[]],\"nextSlide\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"paper-icon\",[\"arrow_forward\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-swiper/templates/components/paper-swiper.hbs"
    }
  });

  _exports.default = _default;
});