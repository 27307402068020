define("ember-paper/templates/components/paper-card-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lg3d6UWN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"text\",\"media\"],[[27,\"component\",[\"paper-card-title-text\"],null],[27,\"component\",[\"paper-card-title-media\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-title.hbs"
    }
  });

  _exports.default = _default;
});