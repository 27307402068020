define("ember-paper-expansion-panel/templates/components/paper-expansion-panel/collapsed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QGaeTo/u",
    "block": "{\"symbols\":[\"controls\",\"&default\"],\"statements\":[[4,\"unless\",[[23,[\"expanded\"]]],null,{\"statements\":[[4,\"paper-item\",null,[[\"class\",\"onClick\",\"onMouseEnter\",\"onMouseLeave\"],[[23,[\"class\"]],[27,\"action\",[[22,0,[]],[23,[\"onExpand\"]]],null],[23,[\"onMouseEnter\"]],[23,[\"onMouseLeave\"]]]],{\"statements\":[[0,\"    \"],[14,2,[[22,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-expansion-panel/templates/components/paper-expansion-panel/collapsed.hbs"
    }
  });

  _exports.default = _default;
});