define("ember-paper/templates/components/paper-card-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/QV57b1Z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"text\",\"avatar\"],[[27,\"component\",[\"paper-card-header-text\"],null],[27,\"component\",[\"paper-card-avatar\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card-header.hbs"
    }
  });

  _exports.default = _default;
});