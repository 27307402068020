define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4m9USmr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[12,\"id\",[21,\"for\"]],[12,\"accept\",[21,\"accept\"]],[12,\"capture\",[21,\"capture\"]],[12,\"multiple\",[21,\"multiple\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[11,\"hidden\",\"\"],[11,\"type\",\"file\"],[9],[10],[14,1,[[23,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});