define('ember-validated-input/components/validated-input', ['exports', 'ember-validated-input/templates/components/validated-input', 'ember-concurrency'], function (exports, _validatedInput, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const DEBOUNCE_TIMEOUT = Ember.testing ? 10 : 150;

  /**
    `validated-input`
  
    ## default usage
  
    ```handlebars
    {{validated-input
      changeset=changeset
      valuePath="name"
      type="text"
      placeholder="Name"
      required=true
    }}
    ```
  
    @class ValidatedInput
    @module Component
    @extends Ember.Component
   */
  exports.default = Ember.Component.extend({
    layout: _validatedInput.default,

    classNames: ['validated-input'],
    classNameBindings: ['hasError:validated-input--error'],
    type: 'text',
    textarea: false,
    disabled: false,
    autocapitalize: 'none', // safari https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/Attributes.html#//apple_ref/doc/uid/TP40008058-autocapitalize
    autocomplete: 'off',
    autocorrect: 'off',
    autofocus: false,
    readonly: false,
    required: false,
    spellcheck: 'false',
    showError: true,
    hasError: false,
    name: null,

    _checkValidity: (0, _emberConcurrency.task)(function* (changeset, snapshot, valuePath, value) {
      yield (0, _emberConcurrency.timeout)(DEBOUNCE_TIMEOUT);
      Ember.set(changeset, valuePath, value);
      if (!changeset.get(`error.${valuePath}`)) {
        Ember.set(this, 'hasError', false);
      } else {
        changeset.restore(snapshot);
      }
    }).restartable(),

    actions: {
      /**
       * @method validateProperty
       * @param {Object} changeset
       * @param {String} valuePath
       * @param {Object} e
       */
      validateProperty(changeset, valuePath, e) {
        Ember.set(changeset, valuePath, e.target.value);

        if (changeset.get(`error.${valuePath}`)) {
          Ember.set(this, 'hasError', true);
        } else {
          Ember.set(this, 'hasError', false);
        }

        if (Ember.get(this, 'onBlur')) {
          Ember.get(this, 'onBlur')(e);
        }
        if (Ember.get(this, 'onInput')) {
          Ember.get(this, 'onInput')(e);
        }
        // return changeset.validate(valuePath);
      },

      /**
       * @method checkValidity
       * @param {Object} changeset
       * @param {String|Integer} value
       */
      checkValidity(changeset, value) {
        const valuePath = Ember.get(this, 'valuePath');
        Ember.get(this, '_checkValidity').perform(changeset, changeset.snapshot(), valuePath, value);
      }
    }
  });
});