define("ember-changeset/index", ["exports", "validated-changeset", "ember-changeset/utils/merge-deep", "ember-changeset/utils/is-object"], function (_exports, _validatedChangeset, _mergeDeep, _isObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.Changeset = Changeset;
  _exports.default = _exports.EmberChangeset = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const CHANGES = '_changes';
  const ERRORS = '_errors';
  const CONTENT = '_content';

  const defaultValidatorFn = () => true; // TODO: Get rid of this heuristic check by passing an option to the Changeset constructor


  const isBelongsToRelationship = obj => {
    if (!obj) {
      return false;
    }

    if (obj.hasOwnProperty('content') && obj.hasOwnProperty('isFulfilled') && obj.hasOwnProperty('isRejected')) {
      // Async belongsTo()
      return true;
    }

    if ('isLoading' in obj && 'isLoaded' in obj && 'isNew' in obj && 'hasDirtyAttributes' in obj) {
      // Sync belongsTo()
      return true;
    }

    return false;
  };

  let EmberChangeset = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class EmberChangeset extends _validatedChangeset.BufferedChangeset {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, '_changes', _descriptor, this);

      _initializerDefineProperty(this, '_errors', _descriptor2, this);

      _initializerDefineProperty(this, '_content', _descriptor3, this);

      _defineProperty(this, "getDeep", Ember.get);
    }

    safeGet(obj, key) {
      return Ember.get(obj, key);
    }
    /**
     * Manually add an error to the changeset. If there is an existing
     * error or change for `key`, it will be overwritten.
     *
     * @method addError
     */


    addError(key, error) {
      super.addError(key, error);
      Ember.notifyPropertyChange(this, ERRORS); // Notify that `key` has changed.

      Ember.notifyPropertyChange(this, key); // Return passed-in `error`.

      return error;
    }
    /**
     * Manually push multiple errors to the changeset as an array.
     *
     * @method pushErrors
     */


    pushErrors(key, ...newErrors) {
      const {
        value,
        validation
      } = super.pushErrors(key, ...newErrors);
      Ember.notifyPropertyChange(this, ERRORS);
      Ember.notifyPropertyChange(this, key);
      return {
        value,
        validation
      };
    }
    /**
     * Sets property or error on the changeset.
     * Returns value or error
     */


    _setProperty({
      key,
      value,
      oldValue
    }) {
      super._setProperty({
        key,
        value,
        oldValue
      }); // Happy path: notify that `key` was added.


      Ember.notifyPropertyChange(this, CHANGES);
      Ember.notifyPropertyChange(this, key);
    }
    /**
     * Notifies virtual properties set on the changeset of a change.
     * You can specify which keys are notified by passing in an array.
     *
     * @private
     * @param {Array} keys
     * @return {Void}
     */


    _notifyVirtualProperties(keys) {
      keys = super._notifyVirtualProperties(keys);
      (keys || []).forEach(key => Ember.notifyPropertyChange(this, key));
      return;
    }
    /**
     * Deletes a key off an object and notifies observers.
     */


    _deleteKey(objName, key = '') {
      const result = super._deleteKey(objName, key);

      Ember.notifyPropertyChange(this, `${objName}.${key}`);
      Ember.notifyPropertyChange(this, objName);
      return result;
    }
    /**
     * Executes the changeset if in a valid state.
     *
     * @method execute
     */


    execute() {
      if (this.isValid && this.isDirty) {
        let content = this[CONTENT];
        let changes = this[CHANGES]; // we want mutation on original object
        // @tracked

        this[CONTENT] = (0, _mergeDeep.default)(content, changes, {
          safeGet: Ember.get,
          safeSet: Ember.set
        });
      }

      return this;
    }
    /**
     * Proxied getter
     * TODO: Evaluate Ember Data particularities here and see how we can use
     * base class `get`
     *
     * @method get
     */


    get(key) {
      // 'person'
      // 'person.username'
      let [baseKey, ...remaining] = key.split('.');

      if (Object.prototype.hasOwnProperty.call(this[CHANGES], baseKey)) {
        let changes = this[CHANGES];
        let result;

        if (remaining.length > 0) {
          let c = changes[baseKey];
          result = this.getDeep((0, _validatedChangeset.normalizeObject)(c), remaining.join('.'));

          if (typeof result !== 'undefined') {
            return result;
          }
        } else {
          result = changes[baseKey];
        }

        if (result !== undefined && result !== null && (0, _isObject.default)(result)) {
          result = (0, _validatedChangeset.normalizeObject)(result);
          let content = this[CONTENT]; // Merge the content with the changes to have a complete object for a nested property.
          // Given a object with nested property and multiple properties inside of it, if the
          // requested key is the top most nested property and we have changes in of the properties, we need to
          // merge the original model data with the changes to have the complete object.
          // eg. model = { user: { name: 'not changed', email: 'changed@prop.com'} }

          if (!Array.isArray(result) && (0, _isObject.default)(content[baseKey]) && !isBelongsToRelationship(content[baseKey])) {
            let data = {};
            Object.keys(content[baseKey]).forEach(k => {
              data[k] = this.getDeep(content, `${baseKey}.${k}`);
            });
            return (0, _validatedChangeset.pureAssign)(data, result);
          }

          return result;
        }

        if (result) {
          return result.value;
        }
      } // return getters/setters/methods on BufferedProxy instance


      if (typeof this[key] !== 'undefined') {
        return this[key];
      } else if (this[baseKey]) {
        const v = this[baseKey];

        if ((0, _isObject.default)(v)) {
          const result = this.getDeep(v, remaining.join('.'));
          return result;
        }
      } // finally return on underlying object


      const result = this.getDeep(this[CONTENT], key);
      return result;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, '_changes', [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, '_errors', [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, '_content', [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  /**
   * Creates new changesets.
   */

  _exports.EmberChangeset = EmberChangeset;

  function changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    (true && !(Boolean(obj)) && Ember.assert('Underlying object for changeset is missing', Boolean(obj)));
    (true && !(!Array.isArray(obj)) && Ember.assert('Array is not a valid type to pass as the first argument to `changeset`', !Array.isArray(obj)));

    if (options.changeset) {
      return new options.changeset(obj, validateFn, validationMap, options);
    }

    const c = new EmberChangeset(obj, validateFn, validationMap, options);
    return c;
  }
  /**
   * Creates new changesets.
   * @function Changeset
   */


  function Changeset(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
    const c = changeset(obj, validateFn, validationMap, options);
    return new Proxy(c, {
      get(targetBuffer, key
      /*, receiver*/
      ) {
        const res = targetBuffer.get(key.toString());
        return res;
      },

      set(targetBuffer, key, value
      /*, receiver*/
      ) {
        targetBuffer.set(key.toString(), value);
        return true;
      }

    });
  }

  class ChangesetKlass {
    /**
     * Changeset factory
     * TODO: deprecate in favor of factory function
     *
     * @class ChangesetKlass
     * @constructor
     */
    constructor(obj, validateFn = defaultValidatorFn, validationMap = {}, options = {}) {
      const c = changeset(obj, validateFn, validationMap, options);
      return new Proxy(c, {
        get(targetBuffer, key
        /*, receiver*/
        ) {
          const res = targetBuffer.get(key.toString());
          return res;
        },

        set(targetBuffer, key, value
        /*, receiver*/
        ) {
          targetBuffer.set(key.toString(), value);
          return true;
        }

      });
    }

  }

  _exports.default = ChangesetKlass;
});