define('ember-paper-swiper/components/paper-swiper/slide', ['exports', 'ember-paper-swiper/templates/components/paper-swiper/slide', 'ember-composability-tools'], function (exports, _slide, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberComposabilityTools.ChildMixin, {
    layout: _slide.default,
    classNames: ['paper-swiper-slide'],
    attributeBindings: ['widthStyle:style'],

    widthStyle: Ember.computed('containerWidth', function () {
      let containerWidth = this.get('containerWidth');
      return containerWidth ? Ember.String.htmlSafe(`width: ${containerWidth}px`) : null;
    }),

    headlineId: Ember.computed('elementId', function () {
      return `${this.elementId}-headline`;
    }),

    subheadId: Ember.computed('elementId', function () {
      return `${this.elementId}-subhead`;
    })
  });
});