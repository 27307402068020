define('ember-paper-swiper/components/paper-swiper/subhead', ['exports', 'ember-paper-swiper/templates/components/paper-swiper/subhead'], function (exports, _subhead) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _subhead.default,
    tagName: ''
  });
});