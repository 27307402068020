define("ember-intl/utils/missing-message", ["exports", "ember-intl/utils/links"], function (_exports, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = missingMessage;

  function missingMessage(key, locales
  /*, options */
  ) {
    if (Ember.isEmpty(locales)) {
      (true && Ember.warn(`[ember-intl] no locale has been set. Documentation: ${_links.default.unsetLocale}`, false, {
        id: 'ember-intl-no-locale-set'
      }));
      return `No locale defined.  Unable to resolve translation: "${key}"`;
    }

    const localeNames = locales.join(', ');
    (true && Ember.warn(`[ember-intl] translation: "${key}" on locale: "${localeNames}" was not found.`, false, {
      id: 'ember-intl-missing-translation'
    }));
    return `Missing translation "${key}" for locale "${localeNames}"`;
  }
});