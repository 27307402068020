define("ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aQYKmabb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"expanded\"]]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"header\",\"content\",\"footer\"],[[27,\"component\",[\"paper-expansion-panel/expanded/header\"],[[\"onClose\"],[[23,[\"onClose\"]]]]],[27,\"component\",[\"paper-expansion-panel/expanded/content\"],null],[27,\"component\",[\"paper-expansion-panel/expanded/footer\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded.hbs"
    }
  });

  _exports.default = _default;
});