define("ember-paper/templates/components/paper-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cMTBR5sn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"title\",\"content\",\"actions\",\"header\",\"image\",\"media\"],[[27,\"component\",[\"paper-card-title\"],null],[27,\"component\",[\"paper-card-content\"],null],[27,\"component\",[\"paper-card-actions\"],null],[27,\"component\",[\"paper-card-header\"],null],[27,\"component\",[\"paper-card-image\"],null],[27,\"component\",[\"paper-card-media\"],null]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-card.hbs"
    }
  });

  _exports.default = _default;
});