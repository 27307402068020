define("ember-paper/templates/components/paper-menu-content-inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7S6WXFW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"menu-item\"],[[27,\"component\",[\"paper-menu-item\"],[[\"dropdown\",\"parentComponent\"],[[23,[\"dropdown\"]],[22,0,[]]]]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-menu-content-inner.hbs"
    }
  });

  _exports.default = _default;
});