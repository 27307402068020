define("ember-paper-swiper/templates/components/paper-swiper/headline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KvxtHeug",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[[23,[\"destinationId\"]]]],{\"statements\":[[14,1]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-swiper/templates/components/paper-swiper/headline.hbs"
    }
  });

  _exports.default = _default;
});