define("ember-paper/templates/components/paper-toaster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "za14moTT",
    "block": "{\"symbols\":[\"toast\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\"],[4,\"if\",[[23,[\"activeToast\",\"show\"]]],null,{\"statements\":[[4,\"paper-toast\",null,[[\"onClose\",\"position\",\"duration\",\"class\",\"parent\"],[[27,\"action\",[[22,0,[]],[23,[\"onClose\"]],[23,[\"activeToast\"]]],null],[23,[\"activeToast\",\"position\"]],[23,[\"activeToast\",\"duration\"]],[23,[\"activeToast\",\"toastClass\"]],[23,[\"parent\"]]]],{\"statements\":[[4,\"if\",[[23,[\"activeToast\",\"componentName\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[23,[\"activeToast\",\"componentName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"text\"]],\"expected `toast.text` to be a contextual component but found a string. Did you mean `(component toast.text)`? ('ember-paper/templates/components/paper-toaster.hbs' @ L12:C9) \"],null]],null,{\"statements\":[[1,[23,[\"activeToast\",\"text\"]],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"activeToast\",\"action\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"primary\",\"accent\",\"warn\",\"onClick\"],[[23,[\"activeToast\",\"action\",\"primary\"]],[23,[\"activeToast\",\"action\",\"accent\"]],[23,[\"activeToast\",\"action\",\"warn\"]],[23,[\"activeToast\",\"action\",\"onClick\"]]]],{\"statements\":[[0,\"          \"],[1,[23,[\"activeToast\",\"action\",\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-toaster.hbs"
    }
  });

  _exports.default = _default;
});