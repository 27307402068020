define("ember-paper/templates/components/paper-subheader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+W31zrXD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"md-subheader-inner\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md-subheader-content\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-subheader.hbs"
    }
  });

  _exports.default = _default;
});