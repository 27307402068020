define('ember-screen/services/screen', ['exports', 'ember-screen/window/browser', 'ember-screen/window/null'], function (exports, _browser, _null) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const isFastBoot = typeof FastBoot !== 'undefined';
  const WindowClass = isFastBoot ? _null.default : _browser.default;

  exports.default = Ember.Service.extend({
    init() {
      this._super(...arguments);
      this.win = new WindowClass();
      this.win.onSizeUpdate(this.handleResize.bind(this));
    },

    willDestroy() {
      this.win.teardown();
    },

    handleResize({ width, height }) {
      this.set('width', width);
      this.set('height', height);
    },

    stubMediaFeatures(features) {
      this.win.stubMediaFeatures(features);
    }
  });
});