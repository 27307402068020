define("ember-pdf-js/templates/components/pdf-js-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sfC8gXcH",
    "block": "{\"symbols\":[\"@changePage\"],\"statements\":[[7,\"button\"],[12,\"disabled\",[27,\"gte\",[[23,[\"page\"]],[23,[\"pageTotal\"]]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n  Next page !\\n\"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],[22,1,[]]],null],\"next\"]],[10],[0,\"\\n\"],[7,\"button\"],[12,\"disabled\",[27,\"lte\",[[23,[\"page\"]],1],null]],[11,\"type\",\"button\"],[9],[0,\"\\n  Prev page !\\n\"],[3,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],[22,1,[]]],null],\"prev\"]],[10],[0,\"\\n\"],[1,[27,\"one-way-input\",[[23,[\"searchTerms\"]]],[[\"update\"],[[27,\"action\",[[22,0,[]],\"updateSearchTerm\"],null]]]],false],[0,\"\\n\"],[7,\"br\"],[9],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[27,\"one-way-checkbox\",[[23,[\"caseSensitive\"]]],[[\"update\"],[[27,\"action\",[[22,0,[]],\"updateCaseSensitive\"],null]]]],false],[0,\"\\n  case sensitive\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[27,\"one-way-checkbox\",[[23,[\"phraseSearch\"]]],[[\"update\"],[[27,\"action\",[[22,0,[]],\"updatePhraseSearch\"],null]]]],false],[0,\"\\n  match phrase\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"\\n  \"],[1,[27,\"one-way-checkbox\",[[23,[\"highlightAll\"]]],[[\"update\"],[[27,\"action\",[[22,0,[]],\"updateHighlightAll\"],null]]]],false],[0,\"\\n  highlightAll\\n\"],[10],[0,\"\\n\"],[7,\"label\"],[9],[0,\"page \"],[1,[21,\"page\"],false],[0,\" of \"],[1,[21,\"pageTotal\"],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-pdf-js/templates/components/pdf-js-toolbar.hbs"
    }
  });

  _exports.default = _default;
});