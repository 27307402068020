define("ember-paper/templates/components/paper-sidenav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ORAfI9C0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[23,[\"closed\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"paper-backdrop\",null,[[\"opaque\",\"class\",\"onClick\"],[true,\"md-sidenav-backdrop\",[27,\"action\",[[22,0,[]],\"onBackdropTap\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"paper-sidenav-inner\",null,[[\"class\",\"name\",\"position\",\"lockedOpen\",\"closed\",\"closeOnClick\",\"onToggle\"],[[23,[\"class\"]],[23,[\"name\"]],[23,[\"position\"]],[23,[\"lockedOpen\"]],[23,[\"closed\"]],[23,[\"closeOnClick\"]],[27,\"action\",[[22,0,[]],\"onToggle\"],null]]],{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-sidenav.hbs"
    }
  });

  _exports.default = _default;
});