define('ember-pdf-js/components/pdf-js-toolbar', ['exports', 'ember-pdf-js/templates/components/pdf-js-toolbar'], function (exports, _pdfJsToolbar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const {
    Component
  } = Ember;

  exports.default = Component.extend({
    layout: _pdfJsToolbar.default,

    // variables
    searchTerms: '',
    caseSensitive: false,
    highlightAll: true,
    phraseSearch: false,
    page: undefined,
    pageTotal: undefined,

    actions: {
      search() {
        let searchTerms = this.get('searchTerms');
        let caseSensitive = this.get('caseSensitive');
        let highlightAll = this.get('highlightAll');
        let phraseSearch = this.get('phraseSearch');

        this.sendAction('search', searchTerms, highlightAll, caseSensitive, phraseSearch);
      },
      updateSearchTerm(newValue) {
        this.set('searchTerms', newValue);
        this.send('search');
      },
      updateCaseSensitive(newValue) {
        this.set('caseSensitive', newValue);
        this.send('search');
      },
      updatePhraseSearch(newValue) {
        this.set('phraseSearch', newValue);
        this.send('search');
      },
      updateHighlightAll(newValue) {
        this.set('highlightAll', newValue);
        this.send('search');
      }
    }
  });
});