define('ember-paper-swiper/components/paper-swiper', ['exports', 'ember-paper-swiper/templates/components/paper-swiper', 'ember-composability-tools'], function (exports, _paperSwiper, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberComposabilityTools.ParentMixin, {
    layout: _paperSwiper.default,
    tagName: '',
    destinationId: 'paper-wormhole',

    currentSlide: 0,
    totalSlides: Ember.computed.reads('childComponents.firstObject.totalSlides'),

    isFirst: Ember.computed.equal('currentSlide', 0),
    isLast: Ember.computed('currentSlide', 'totalSlides', function () {
      return this.get('currentSlide') === this.get('totalSlides') - 1;
    }),

    actions: {
      previousSlide() {
        this.decrementProperty('currentSlide');
      },

      nextSlide() {
        this.incrementProperty('currentSlide');
      }
    }
  });
});