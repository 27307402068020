define("ember-paper/templates/components/paper-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LzYNsHj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"shouldRenderButton\"]]],null,{\"statements\":[[4,\"paper-button\",null,[[\"onClick\",\"href\",\"target\",\"disabled\"],[[27,\"action\",[[22,0,[]],\"handleClick\"],null],[23,[\"href\"]],[23,[\"target\"]],[23,[\"disabled\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-menu-item.hbs"
    }
  });

  _exports.default = _default;
});