define("ember-paper-swiper/templates/components/paper-swiper/container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PwAm5UXz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"paper-swiper-wrapper\"],[12,\"style\",[21,\"containerOffset\"]],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"slide\",\"callout\",\"currentSlide\"],[[27,\"component\",[\"paper-swiper/slide\"],[[\"containerWidth\",\"parentComponent\"],[[23,[\"containerWidth\"]],[23,[\"swiper\"]]]]],[27,\"component\",[\"paper-swiper/callout\"],[[\"calloutId\"],[[23,[\"calloutId\"]]]]],[23,[\"currentSlide\"]]]]]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper-swiper/templates/components/paper-swiper/container.hbs"
    }
  });

  _exports.default = _default;
});