define('ember-changeset/utils/includes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = includes /*:: <T> */;
  function includes(arr /*: Array<T> */
  , ...args /*: Array<T> */
  ) /*: boolean */{
    (true && !(Ember.typeOf(arr) === 'array') && Ember.assert('must be array', Ember.typeOf(arr) === 'array'));

    let wrapped = Ember.A(arr);
    let inclusionFn = wrapped.includes || wrapped.contains;

    return inclusionFn.apply(arr, args);
  }
});