define('ember-changeset-cp-validations/helpers/changeset', ['exports', 'ember-changeset-cp-validations'], function (exports, _emberChangesetCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changeset = changeset;
  function changeset([obj, fn]) {
    return (0, _emberChangesetCpValidations.default)(obj, fn);
  }

  exports.default = Ember.Helper.helper(changeset);
});