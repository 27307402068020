define('ember-paper-swiper/components/paper-swiper-inline', ['exports', 'ember-paper-swiper/templates/components/paper-swiper-inline', 'ember-composability-tools'], function (exports, _paperSwiperInline, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberComposabilityTools.ParentMixin, _emberComposabilityTools.ChildMixin, {
    layout: _paperSwiperInline.default,
    tagName: '',

    currentSlide: 0,
    totalSlides: Ember.computed.reads('childComponents.length'),

    isFirst: Ember.computed.equal('currentSlide', 0),
    isLast: Ember.computed('currentSlide', 'totalSlides', function () {
      return this.get('currentSlide') === this.get('totalSlides') - 1;
    }),

    bullets: Ember.computed('childComponents.[]', 'currentSlide', function () {
      let currentSlide = this.get('currentSlide');
      return this.get('childComponents').map((_, i) => ({ isActive: i === currentSlide, index: i }));
    }),

    init() {
      this._super(...arguments);
      this.set('calloutId', `${Ember.guidFor(this)}-callout`);
    },

    actions: {
      previousSlide() {
        this.decrementProperty('currentSlide');
      },

      nextSlide() {
        this.incrementProperty('currentSlide');
      }
    }
  });
});