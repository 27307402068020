define("virtual-each/components/virtual-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STRXFjnY",
    "block": "{\"symbols\":[\"item\",\"idx\",\"&inverse\",\"&default\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"infinite-list-content\"],[12,\"style\",[21,\"contentStyle\"]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"visibleItems\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[28,[\"virtual-item visible-item-\",[22,2,[]]]]],[9],[0,\"\\n    \"],[14,4,[[22,1,[\"raw\"]],[22,1,[\"actualIndex\"]],[22,1,[\"virtualIndex\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"virtual-list-empty\"],[9],[0,\"\\n    \"],[14,3],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "virtual-each/components/virtual-each/template.hbs"
    }
  });

  _exports.default = _default;
});