define("emberx-file-input/templates/components/x-file-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0vmDqkxz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"input\"],[12,\"id\",[21,\"randomId\"]],[11,\"class\",\"x-file--input\"],[12,\"name\",[21,\"name\"]],[12,\"disabled\",[21,\"disabled\"]],[12,\"multiple\",[21,\"multiple\"]],[12,\"tabindex\",[21,\"tabindex\"]],[12,\"accept\",[21,\"accept\"]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[7,\"label\"],[12,\"for\",[28,[[21,\"randomId\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"alt\"],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "emberx-file-input/templates/components/x-file-input.hbs"
    }
  });

  _exports.default = _default;
});