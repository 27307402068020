define("ember-basic-dropdown/templates/components/basic-dropdown/content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LpGlZfJv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"dropdown\",\"isOpen\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\",\"renderInPlace\",\"class\"],[[23,[\"to\"]],[23,[\"renderInPlace\"]],\"ember-basic-dropdown-content-wormhole-origin\"]],{\"statements\":[[4,\"if\",[[23,[\"overlay\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"ember-basic-dropdown-overlay\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"basic-dropdown/content-element\",null,[[\"tagName\",\"id\",\"class\",\"style\",\"dir\"],[[23,[\"_contentTagName\"]],[23,[\"dropdownId\"]],[27,\"concat\",[\"ember-basic-dropdown-content \",[23,[\"class\"]],\" \",[23,[\"defaultClass\"]],\" \",[27,\"if\",[[23,[\"renderInPlace\"]],\"ember-basic-dropdown-content--in-place \"],null],[27,\"if\",[[23,[\"hPosition\"]],[27,\"concat\",[\"ember-basic-dropdown-content--\",[23,[\"hPosition\"]]],null]],null],\" \",[27,\"if\",[[23,[\"vPosition\"]],[27,\"concat\",[\"ember-basic-dropdown-content--\",[23,[\"vPosition\"]]],null]],null],\" \",[23,[\"animationClass\"]]],null],[23,[\"style\"]],[23,[\"dir\"]]]],{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[21,\"dropdownId\"]],[11,\"class\",\"ember-basic-dropdown-content-placeholder\"],[11,\"style\",\"display: none;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown/content.hbs"
    }
  });

  _exports.default = _default;
});