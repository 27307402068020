define("ember-paper/components/paper-autocomplete-trigger-container", ["exports", "ember-basic-dropdown/components/basic-dropdown/trigger"], function (_exports, _trigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _trigger.default.extend({
    attributeBindings: ['label:md-floating-label', 'disabled:disabled']
  });

  _exports.default = _default;
});