define('ember-changeset-cp-validations/index', ['exports', 'ember-changeset'], function (exports, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildChangeset = buildChangeset;
  exports.default = createChangeset;
  function buildChangeset(model) {
    (true && !(Ember.typeOf(model.get('validations')) === 'instance') && Ember.assert('Object does not contain any validations', Ember.typeOf(model.get('validations')) === 'instance'));


    return {
      validationMap: model.get('validations.validatableAttributes').reduce((o, attr) => {
        o[attr] = true;
        return o;
      }, {}),

      validateFn: ({ key, newValue }) => {
        return model.validateAttribute(key, newValue).then(({ validations }) => {
          return validations.get('isValid') ? true : validations.get('message');
        });
      }
    };
  }

  function createChangeset(model, fn) {
    let { validateFn, validationMap } = buildChangeset(model);
    let _fn;

    if (fn && typeof fn === 'function') {
      _fn = function () {
        return fn(...arguments, validateFn);
      };
    }

    return new _emberChangeset.default(model, _fn || validateFn, validationMap);
  }
});