define("ember-intl/hydrate", ["exports", "ember-intl/utils/links"], function (_exports, _links) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lookupByFactoryType = lookupByFactoryType;
  _exports.default = _default;

  /* globals requirejs */

  /**
   * @private
   * @hide
   */
  function lookupByFactoryType(type, modulePrefix) {
    return Object.keys(requirejs.entries).filter(key => {
      return key.indexOf(`${modulePrefix}/${type}/`) === 0;
    });
  }
  /**
   * Peeks into the requirejs map and registers all locale data (CLDR & Translations) found.
   *
   * @private
   * @hide
   */


  function _default(service, owner) {
    const config = owner.resolveRegistration('config:environment');
    const cldrs = lookupByFactoryType('cldrs', config.modulePrefix);
    const translations = lookupByFactoryType('translations', config.modulePrefix);

    if (!cldrs.length) {
      (true && Ember.warn(`[ember-intl] project is missing CLDR data\nIf you are asynchronously loading translation,
      see: ${_links.default.asyncTranslations}.`, false, {
        id: 'ember-intl-missing-cldr-data'
      }));
    }

    cldrs.map(moduleName => owner.resolveRegistration(`cldr:${moduleName.split('/').pop()}`)).forEach(data => data.forEach(service.addLocaleData));
    translations.forEach(moduleName => {
      const localeName = moduleName.split('/').pop();
      service.addTranslations(localeName, owner.resolveRegistration(`translation:${localeName}`));
    });
  }
});