define("ember-changeset-validations/validators/date", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-changeset-validations/utils/to-date"], function (_exports, _validationErrors, _withDefaults, _toDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;
  _exports.errorFormat = void 0;
  const errorFormat = "MMM Do, YYYY";
  _exports.errorFormat = errorFormat;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function validateDate(options = {}) {
    options = (0, _withDefaults.default)(options, {
      allowBlank: false,
      errorFormat: errorFormat
    });
    return (key, value) => {
      let {
        allowBlank
      } = options;
      let {
        before,
        onOrBefore,
        after,
        onOrAfter
      } = options;

      if (allowBlank && (typeof value === 'undefined' || value === null)) {
        return true;
      }

      let date = (0, _toDate.default)(value);

      if (!isValidDate(date)) {
        return (0, _validationErrors.default)(key, {
          type: 'date',
          value: 'not a date'
        });
      }

      if (before) {
        before = (0, _toDate.default)(before);

        if (date >= before) {
          return (0, _validationErrors.default)(key, {
            value,
            message: `[BEFORE] date is NOT before ${value}`
          });
        }
      }

      if (onOrBefore) {
        onOrBefore = (0, _toDate.default)(onOrBefore);

        if (date > onOrBefore) {
          return (0, _validationErrors.default)(key, {
            value,
            message: `[ON OR BEFORE] date is NOT on or before ${value}`
          });
        }
      }

      if (after) {
        after = (0, _toDate.default)(after);

        if (date <= after) {
          return (0, _validationErrors.default)(key, {
            value,
            message: `[AFTER] date is NOT after ${value}`
          });
        }
      }

      if (onOrAfter) {
        onOrAfter = (0, _toDate.default)(onOrAfter);

        if (date < onOrAfter) {
          return (0, _validationErrors.default)(key, {
            value,
            message: `[ON OR AFTER] date is NOT on or after ${value}`
          });
        }
      }

      return true;
    };
  }
});