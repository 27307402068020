define("ember-pdf-js/templates/components/pdf-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UoYdeRA5",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"component\",[[23,[\"toolbarComponent\"]]],[[\"changePage\",\"changeSearchResult\",\"goToMatch\",\"search\",\"zoom\",\"page\",\"pageTotal\",\"class\",\"currentMatch\",\"currentMatchIdx\",\"matchTotal\",\"searchResultContexts\"],[[27,\"action\",[[22,0,[]],\"changePage\"],null],[27,\"action\",[[22,0,[]],\"changeSearchResult\"],null],[27,\"action\",[[22,0,[]],\"goToMatch\"],null],[27,\"action\",[[22,0,[]],\"search\"],null],[27,\"action\",[[22,0,[]],\"zoom\"],null],[23,[\"pdfPage\"]],[23,[\"pdfTotalPages\"]],\"ember-pdf-js toolbar\",[23,[\"currentMatch\"]],[23,[\"currentMatchIdx\"]],[23,[\"matchTotal\"]],[23,[\"searchResultContexts\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"pdfViewerContainer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pdfViewer\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-pdf-js/templates/components/pdf-js.hbs"
    }
  });

  _exports.default = _default;
});