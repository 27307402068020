define('ember-paper-swiper/components/paper-swiper/headline', ['exports', 'ember-paper-swiper/templates/components/paper-swiper/headline'], function (exports, _headline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _headline.default,
    tagName: ''
  });
});