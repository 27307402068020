define("ember-file-upload/system/parse-json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseJSON;

  function parseJSON(string) {
    let json = null;

    try {
      json = JSON.parse(string);
    } catch (e) {
      (true && !(false) && Ember.assert(`Invalid JSON: ${string}`));
    }

    return json;
  }
});